<template>
    <div class="main-page container is-fluid">
        <div class="is-hidden-mobile"><br></div>
        <section class="main-section">
            <div class="text-outside is-hidden-mobile">
                <ul>
                    <li>Zakázková výroba od <b>1 kusu</b></li>
                    <li>Vybavení obchodů v bedýnkovém stylu </li>
                    <li>Na naše produkty Vám vygravírujeme <b>text nebo logo</b>, podle Vašeho přání</li>
                </ul>
            </div>
            <div class="text-inside">Vítejte ve světě bedýnek<span class="has-text-black">!</span></div>
        </section>
        <section class="specs">
            <div class="tagline">
                Jsme regionální výrobci kvalitních bedýnek
            </div>
            <div class="is-hidden-mobile">
                <br>
            </div>
            <br>
            <div class="is-hidden-tablet">
                <ul>
                    <li>Zakázková výroba od <b>1 kusu</b></li>
                    <li>Vybavení obchodů v bedýnkovém stylu </li>
                    <li>Na naše produkty Vám vygravírujeme <b>text nebo logo</b>, podle Vašeho přání</li>
                </ul>
            </div>
            <Pins class=" is-hidden-mobile" />
        </section>
        <div class="is-hidden-mobile">
            <br>
        </div>
        <br>
        <section class="categories">
            <div class="columns is-mobile">
                <div class="column">
                    <router-link tag="div" to="/kategorie/boxy-a-truhly/natural" class="category_b prirodni_bedynky">
                        <div class="category_text">
                            <div class="columns is-mobile is-variable is-1">
                                <div class="column is-narrow"><img src="../assets/icons/box.png"
                                        style="filter:invert(1)"></div>
                                <div class="column is-narrow">
                                    Přírodní <span class="is-hidden-tablet"><br></span>bedýnky ></div>
                            </div>
                        </div>
                    </router-link>
                    <br>
                    <router-link tag="div" to="/kategorie/boxy-a-truhly/tanned" class="category_b opalene_bedynky">
                        <div class="category_text">
                            <div class="columns is-mobile is-variable is-1">
                                <div class="column is-narrow"><img src="../assets/icons/box-tan.png"
                                        style="filter:invert(1)"></div>
                                <div class="column is-narrow">
                                    Opálené <span class="is-hidden-tablet"><br></span>bedýnky ></div>
                            </div>
                        </div>

                    </router-link>
                </div>
                <div class="column">
                    <router-link tag="div" to="/kategorie/boxy-a-truhly/boxes" class="category_a boxy_boxy">
                        <div class="category_text">
                            <div class="columns is-mobile is-variable is-1">
                                <div class="column is-narrow"><img src="../assets/icons/holder.png"
                                        style="filter:invert(1)"></div>
                                <div class="column is-narrow">
                                    Boxy ></div>
                            </div>
                        </div>

                    </router-link>
                </div>
            </div>
            <div class="columns is-mobile">
                <div class="column">
                    
                    <router-link tag="div" to="/kategorie/cutouts" class="category_b vyrezy">
                        <div class="category_text">
                            <div class="columns is-mobile is-variable is-1">
                                <div class="column is-narrow"><img src="../assets/icons/saw.png"
                                        style="filter:invert(1)"></div>
                                <div class="column is-narrow">
                                    Výřezy ></div>
                            </div>
                        </div>

                    </router-link>
                </div>
            </div>
        </section>
        <div class="is-hidden-tablet">
            <br>
            <br>
        </div>
        <Pins class="is-hidden-tablet" />
        <Footer></Footer>
    </div>
</template>
<style>
    .category_text {
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 1.2rem;
        color: white;
        font-weight: bold;
    }

    .category_a {
        width: 100%;
        height: 100%;
        border-radius: 15px;
        background-position: center;
        background-size: cover;
        position: relative;
        cursor: pointer;
        transition: all 0.4s ease;
    }

    .category_b {
        width: 100%;
        height: 180px;
        border-radius: 15px;
        background-position: center;
        background-size: cover;
        position: relative;
        cursor: pointer;
        transition: all 0.8s ease;
    }

    .category_a:hover,
    .category_b:hover {
        filter: grayscale(100%) opacity(80%);
    }

    .prirodni_bedynky {
        background-image: url("../assets/raw_cat.jpg");
        box-shadow: rgba(207, 187, 122, 0.425) 0px 25px 50px -12px, inset 34px 43px 84px 12px rgba(0, 0, 0, 0.5);

    }

    .opalene_bedynky {
        background-image: url("../assets/tan_cat.jpg");
        box-shadow: rgba(170, 150, 84, 0.425) 0px 25px 50px -12px, inset 34px 43px 84px 12px rgba(0, 0, 0, 0.5);

    }

    .vyrezy {
        background-image: url("../assets/vyrezy2.jpg");
        box-shadow: rgba(170, 150, 84, 0.425) 0px 25px 50px -12px, inset 34px 43px 84px 12px rgba(0, 0, 0, 0.5);

    }

    .boxy_boxy {
        background-image: url("../assets/boxy_cat.png");
        box-shadow: rgba(148, 140, 113, 0.808) 0px 25px 50px -12px, inset 34px 43px 84px 12px rgba(0, 0, 0, 0.5);
    }

    .top0 {
        width: 70px;
    }

    .top1 {
        font-weight: bold;

    }

    .top2 {
        font-size: .8rem;

    }

    .main-section {
        width: 100%;
        box-shadow: rgba(47, 99, 41, 0.25) 0px 25px 50px -12px;
        height: 300px;
        background-image: url("../assets/background.jpg");
        background-position: center bottom;
        background-size: cover;
        border-radius: 15px;
        position: relative;
        margin-bottom: 90px;
    }

    .tagline {
        text-align: center;
        font-size: 1.8rem;
        line-height: 1.8rem;
        color: rgb(33, 51, 30);
        font-weight: 600;
    }

    .text-inside {

        box-shadow: rgba(91, 136, 85, 0.25) 0px 25px 50px -12px;
        color: rgb(91, 136, 85);
        background-color: white;
        border-radius: 15px;
        position: absolute;
        top: 50px;
        width: auto;
        left: 50px;
        font-weight: 900;
        font-size: 2rem;
        padding: 10px 20px;
    }

    .text-outside {
        box-shadow: rgba(91, 136, 85, 0.25) 0px 25px 50px -12px, inset rgba(31, 63, 27, 0.479) 0px 25px 50px -12px;
        background-color: rgb(91, 136, 85);
        color: white;
        padding: 20px;
        border-radius: 15px;
        position: absolute;
        bottom: -50px;
        width: 300px;
        right: 50px;
        z-index: 20;

    }

    small {
        line-height: 0.875em !important;
    }

    @media screen and (max-width: 768px) {
    
        .text-inside {

            width: calc(100% - 100px);
            left: 0;
            top: 0;
            text-align: center;
            margin: 50px;
            font-weight: 900;
            font-size: 1.5rem;
        }

        .main-section {
            margin-bottom: 30px;
        }

        .text-outside {
            width: calc(100% - 50px);

            padding: 20px;
            border-radius: 15px;
            position: absolute;
            bottom: -100px;
            right: 0;
            margin: 25px;
            z-index: 20;
            font-size: 0.9rem;
        }
    }
</style>
<script>
    import Footer from "@/components/Footer.vue";
    import Pins from "@/components/Pins.vue";
    export default {
        name: 'veShopTopbar',
        title: "Bedýnky ze dřeva",
        components: {
            Footer,
            Pins
        },
        data() {
            return {}
        },
        computed: {

        },
        methods: {

        },
        mounted() {
            this.$Progress.finish();
        }


    }
</script>