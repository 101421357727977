<template>
  <center>
    <h1 class="title">Stránka nenalezena</h1>
    <p class="subtitle">
      <a href="/">Zpět domů</a>
    </p>
  </center>
</template>

<script>

  export default {
    name: 'NotFound',
    title: "Stránka nenalezena",
    mounted()
    {
      this.$Progress.finish();
    }
  }

</script>

<style scoped>

  center {
    margin: 15vw;
  }

  h1 {
    color: var(--border);
    font-size: 2em;
  }

</style>