import Vue from 'vue'
import VueRouter from 'vue-router'
import MainPage from '../views/MainPage.vue'
import Category1 from '../views/Category.vue'
import Category2 from '../views/Category.vue'
import Product from '../views/Product.vue'
import Cart from '../views/Cart.vue'
import NotFound from '../views/NotFound.vue'
import Categories from '../views/Categories.vue'
import OrderSent from '../views/OrderSent.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Domov',
    component: MainPage
  },
  {
    path: '/kategorie',
    name: 'Kategorie',
    component: Categories
  },
  {
    path: '/kosik',
    name: 'Košík',
    component: Cart
  },
  {
    path: '/objednavka',
    name: 'Objednávka',
    component: OrderSent
  },
  {
    path: '/kategorie/:technical',
    name: 'Kategorie',
    component: Category1,
    props: (route) => ({technical: Vue.observable(new Counter(route.params.technical))}),
  },
  {
    path: '/kategorie/*/:technical',
    name: 'Podkategorie',
    component: Category2,
    props: (route) => ({technical: Vue.observable(new Counter(route.params.technical))}),
  },
  {
    path: '/produkt/*',
    name: 'Produkt',
    component: Product
  },
  {
    path: '*',
    name: 'Not Found',
    component: NotFound
  }
  ,
  {
    path: 'undefined',
    name: 'Not Found',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

class Counter {
  constructor(value) {
    this.technical = value
  }
}


export default router
