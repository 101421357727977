<template>
    <div class="container is-fluid" v-if="loaded">
        <b-breadcrumb
            size="is-small"
        >
            <b-breadcrumb-item tag='router-link' to="/">{{eshop.name}}</b-breadcrumb-item>
            <b-breadcrumb-item tag='router-link' to="/kategorie" active>Kategorie</b-breadcrumb-item>
        </b-breadcrumb>
        
        <div class="columns category-head">
            <div class="column is-narrow">
                <h1 class="title has-text-black" @click="loadCategories()">Kategorie</h1>
            </div>
            <div class="column"></div>
            <div class="column is-narrow"></div>
        </div>
        <div class="columns is-multiline">
            <div class="column is-narrow" v-for="category in categories" :key="category.slug">
                <div class="columns is-multiline fade-in-bottom" :style="category.delay">
                    <div class="column is-narrow">
                        <router-link class="category-box" tag="button" :to="category.slug">
                            <img :src="category.icon" :alt="category.name">
                            <p class="name">{{ category.name }}</p>

                        </router-link>
                    </div>
                    <div class="column is-narrow" v-for="subcategory in category.subcategories.data" :key="subcategory.slug">
                        <router-link class="subcategory-box" tag="button" :to="subcategory.slug">
                            <img :src="subcategory.image" alt="subcategory.name">
                            <p class="name">{{ subcategory.name }}</p>
                            <div class="counter">

                                <div class="count">{{ subcategory.productsNo }}</div>
                                <div class="countTag">{{ subcategory.productsNo > 2 ? (subcategory.productsNo > 4 ? "PRODUKTŮ" : "PRODUKTY" ) : "PRODUKT" }}</div>
                            </div>
                        </router-link>

                    </div>
                </div>
                
                
                    
                
                
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: 'veShopCategoryView',
    components: {
    },
    title: "Kategorie - Bedýnky ze dřeva",
    data() {
        return {
            loaded: false,
            eshop: {
                name: "Bedýnky ze dřeva"
            },
            categories: 
            [
            /*{
                name: "Boxy a truhly",
                slug: "/kategorie/boxy-a-truhly",
                productsNo: 16,
                icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAABg2lDQ1BzUkdCIElFQzYxOTY2LTIuMQAAKJF1kctLQkEUhz+1KMowyEWLCIlqZaEFUpsWSllQLcyg10avr8DH5V4lpG3QViiI2vRa1F9Q26B1EBRFEO2C1kVtSm7nqmBEzTDnfPxmzmHmN2ANp5WM3uCBTDavhYJ+18LikqvpGTvdOBnEEVF0dWZuIsy/4+MOi5lvBsxe/5/7c7TG4roClmbhMUXV8sKTwtNredXkbWGnkorEhE+F3ZpcUPjW1KNVfjE5WeUvk7VwKADWdmFX8gdHf7CS0jLC8nJ6M+mCUruP+RJ7PDs/J7lHVhc6IYL4cTHFOAF8eBmV6GOAIXHIK979Xe+p1M+Sk1pFokoRjVWSpMjjFrUg3eOSE6LHZaYpmv7/9lVPDA9Vu9v90PhkGG990LQF5ZJhfB4aRvkIbI9wka3X5w5g5F30Ul3r3QfHBpxd1rXoDpxvQueDGtEiFckmy5pIwOsJtC1CxzW0LFc9q+1zfA/hdfmqK9jdg34571j5BqDraAAxrQ6jAAAACXBIWXMAAAsTAAALEwEAmpwYAAABAklEQVRIie3VMUoDQRTG8Z9Ru4AIIhgbjWAhgofwKHba5ihqlzN4AvEEJhHEQtC1E0QIgp1ELeYRliUs7FgJ+8HAvI95858ZmPdo1UBLC7wznGAtY78PDHFRNlcWAM5xF6Op+pGvCiprhAk6GQCRN8Ft2azeZB1dXGdCYBufdRBYDdgelvEY3gFe8YZNbOEBX9jHDE+xtlYFrmJ+g3HMe/jBIOJBxL2Ix7Fe5BflTXPfvpFaSAv555BqFS6k3/4i/8fvYIrdOkgX9384+KFUu+aQau2aSj3hGN8ZgI5UyWdlswoZSv1ghOcMSB9HUl+aa1FnPJU640YG5F066GVGbqukX7IqNTGIUccfAAAAAElFTkSuQmCC",
                description: "<p>Velmi kvalitn&iacute; dřevenn&eacute; bed&yacute;nky a truhly, kter&eacute; jsou skvělou a <strong>ekologickou</strong> n&aacute;hradou za plastov&eacute; boxy. Přen&aacute;&scaron;ejte sv&eacute; věci a ceniny stylově!</p>",
                subcategories:
                [
                    {
                        name: "Opálené bedny",
                        image: "https://cdn.vespotok.net/img/palena-bedna.png",
                        slug: "/kategorie/boxy-a-truhly/palene-drevenne-bedny",
                        productsNo: 1
                    },
                    {
                        name: "Opálené bedny",
                        image: "https://cdn.vespotok.net/img/palena-bedna.png",
                        slug: "/kategorie/boxy-a-truhly/palene-drevennce-bedny",
                        productsNo: 15
                    }
                ]
            },*/
            ]
            
        }
    },
    methods: {
    async loadCategories() {
      var data = new FormData();
      data.append('bin', '/apps/persephone/cgi/categories');
      data.append('stdin', 'get_main_categories(1)');
      const hostname = "https://bedynkyzedreva.cz/fujin3/api/rest/rest_call.php";
      axios.post(hostname, data, { headers: {'Content-Type': 'application/json'}})
      .then((response) => {
        this.categories = response.data.data;
        this.loaded = true;
        this.$Progress.finish();
      })
      .catch(function (error) {
        console.log(error);
      });
  }
    },
    mounted() {
      this.loadCategories();
    }
}
</script>