<template>
    <div class="container is-fluid" v-if="loaded">
        <b-breadcrumb size="is-small">
            <b-breadcrumb-item tag='router-link' to="/">{{eshop.name}}</b-breadcrumb-item>
            <b-breadcrumb-item tag='router-link' to="/kategorie">Kategorie</b-breadcrumb-item>
            <b-breadcrumb-item tag='router-link' :to="category.slug" active>{{category.name}}</b-breadcrumb-item>
        </b-breadcrumb>

        <div class="columns is-center is-mobile category-head">
            <div class="column is-narrow">
                <h1 class="title has-text-black"><img :src="category.icon">&nbsp;{{category.name}}</h1>
            </div>
            <div class="column"></div>
            <div class="column is-narrow"></div>
        </div>
        <div class="content" v-html="category.description">
            <b-skeleton></b-skeleton>
        </div>
        <div class="columns is-center is-mobile is-multiline">
            <div class="column is-narrow" v-for="subcategory in category.subcategories.data" :key="subcategory.slug">
                <router-link class="subcategory-box" tag="button" :to="subcategory.slug">
                    <img :src="subcategory.image" alt="subcategory.name">
                    <p class="name">{{ subcategory.name }}</p>
                    <div class="counter">

                        <div class="count">{{ subcategory.productsNo }}</div>
                        <div class="countTag">{{ subcategory.productsNo > 2 ? (subcategory.productsNo > 4 ? "PRODUKTŮ" :
                            "PRODUKTY" ) : "PRODUKT" }}</div>
                    </div>
                </router-link>

            </div>
        </div>
        <Products :categoryId="categoryId" :subcategoriesIds="subcategoriesIds" />
        <Footer></Footer>
    </div>
</template>

<script>
import Products from '@/components/Products.vue';
import Footer from "@/components/Footer.vue";

import axios from "axios";
export default {
    name: 'veShopCategoryView',
    components: {
        Products, Footer
    },
    props: {
      technical: Object
    },
    title: "Boxy a truhly - Bedýnky ze dřeva",
    data() {
        return {
            loaded: false,
            categoryId: 0,
            subcategoriesIds: [],
            eshop: {
                name: "Bedýnky ze dřeva"
            },
            category: {
            }
        }
    },
    methods: {
          async loadCategory(category) {
              var data = new FormData();
              data.append('bin', '/apps/persephone/cgi/categories');
              data.append('stdin', 'get_category('+category+')');
              const hostname = "https://bedynkyzedreva.cz/fujin3/api/rest/rest_call.php";
              axios.post(hostname, data, { headers: {'Content-Type': 'application/json'}})
              .then((response) => {
                this.category = response.data.data;
                this.categoryId = response.data.data.ID;
                this.subcategoriesIds = response.data.data.subcategories.ids;
                this.loaded = true;
                
                this.$Progress.finish();
              })
              .catch(function (error) {
                console.log(error);
              });
          }
    },
    created(){
        this.loaded = false;
        this.loadCategory(this.$props.technical.technical);
    },
    watch: {
        technical: function(newVal) { 
          this.loaded = false;
          this.loadCategory(newVal.technical);
        }
    }
}
</script>