import Vue from 'vue'
import App from './App.vue'
import titleMixin from './titleMixin'
import VueRouter from 'vue-router'
import './assets/custom.scss'
import VueProgressBar from 'vue-progressbar'
import Buefy from 'buefy'
import '@mdi/font/css/materialdesignicons.css'
import VueMeta from 'vue-meta'
import vuescroll from 'vuescroll';
import VueFacebookPixel from '@blaaat/vue-facebook-pixel';
import VueGtag from "vue-gtag";
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';
import VueCookies from 'vue-cookies';
import './validations';
Vue.use(VueGtag, {
  config: { id: "G-HGMQJFVDW5" }
});

Vue.use(Buefy, {
    customIconPacks: {
        'mdi': {
            sizes: {
                'default': 'mdi-20px',
                'is-small': 'mdi-20px',
                'is-medium': 'mdi-20px',
                'is-large': 'mdi-24px'
            }
        }
    }
})
Vue.use(vuescroll, {
 ops: {
          vuescroll: {
            
          },
          scrollPanel: {
          },
          rail: {},
          bar: {
            showDelay: 500,
    onlyShowBarOnScroll: true,
    keepShow: false,
    background: '#c1c1c1',
    opacity: 1,
    hoverStyle: false,
    specifyBorderRadius: false,
    minSize: 0,
    size: '6px',
    disable: false
          }
        },
  name: 'myScroll' // customize component name, default -> vueScroll
});
Vue.use(VueCookies, { expires: '7d'})
Vue.use(VueRouter)
Vue.mixin(titleMixin)
import './assets/main.css'
import router from './router'
Vue.config.productionTip = false
Vue.use(VueProgressBar, {
  color: '#8a7938',
  failedColor: 'red',
  height: '2px'
})
Vue.use(VueMeta);
Vue.use(VueFacebookPixel,{
 
  router, // Router instance (nepovinné, pokud nepoužíváte router)
  autoTracking: {
    pageviewOnLoad: false, // neodesílat PageView automaticky po načtení stránky
  },
  facebookPixel: {
    track: 'PageView',
    pixelId: '1272926589989369',
    disabled: false, // pokud chcete vypnout Facebook Pixel (nepovinné)
  },
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
