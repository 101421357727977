import { ValidationProvider, extend } from 'vee-validate/dist/vee-validate.full.esm';
import { required, min } from 'vee-validate/dist/rules';
import Vue from 'vue'
Vue.component('ValidationProvider', ValidationProvider);

extend('required', {
    ...required,
    message: "Toto pole je povinné"
});

extend('min', {
    ...min,
    message: "Toto pole musí mít minimálně tři znaky"
});