<template>
    <div class="columns is-mobile is-multiline is-centered">
        <div class="columns is-mobile">
            <div class="column">
                <div class="columns">
                    <div class="column">
                        <div class="columns is-mobile is-vcentered is-variable is-1">
                            <div class="column is-narrow"><img src="../assets/nextday.svg" class="top0" /></div>
                            <div class="column">
                                <p class="top1">
                                    Rychlé odeslání
                                </p>
                                <p class="top2">Odesíláme hned druhý pracovní den</p>
                            </div>
                        </div>


                    </div>
                    <div class="column">
                        <div class="columns is-mobile is-vcentered is-variable is-1">
                            <div class="column is-narrow"><img src="../assets/handmade.svg" class="top0" />
                            </div>
                            <div class="column">
                                <p class="top1">
                                    Ruční výroba
                                </p>
                                <p class="top2">Každou bedýnku ručně tvoříme s láskou</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="column">
                <div class="columns">
                    <div class="column">
                        <div class="columns is-mobile is-vcentered is-variable is-1">
                            <div class="column is-narrow"><img src="../assets/bohemianparais.svg" class="top0" /></div>
                            <div class="column">
                                <p class="top1">
                                    Vytvořeno v Českém ráji
                                </p>
                                <p class="top2">Sídlíme v srdci Českého ráje</p>
                            </div>
                        </div>

                    </div>
                    <div class="column">
                        <div class="columns is-mobile is-vcentered is-variable is-1">
                            <div class="column is-narrow"><img src="../assets/freeshipping.svg" class="top0" />
                            </div>
                            <div class="column">
                                <p class="top1">
                                    Doprava zdarma
                                </p>
                                <p class="top2">Nad 2000 Kč doprava zdarma</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>



    </div>
</template>
<script>
export default {
    name: 'veShopFooter',
    components: {
    }}
</script>