<template>
    <div style="display:flex; flex-direction:row;height: calc(100vh - 120px)">
        <div class="container" style="height: calc(100vh - 120px)">
        <div class="container is-fluid" style="height: calc(100vh - 120px)">
        <div class="columns is-vcentered" style="height: calc(100vh - 120px)">
            <div class="column is-one-quarter">
                <div class="box is-shadowless has-background-success-light has-text-centered">
                    <b-icon
                        pack="fas"
                        icon="check-circle"
                        type="is-success"
                        size="is-large">
                    </b-icon>
                    <br>
                    <h1 class="title has-text-centered">
                        <p class="has-text-success">Objednávka přijata</p>
                    </h1>
                </div>
            </div>
            <div class="column">
                <p>Vážený zákazníku,</p><br>
                <p>Mnohokrát Vám děkujeme za objednávku. O jejím zpracování Vás budeme informovat <b>emailem</b>. Pokud
                    jste vybral/a platbu dobírkou, vedle potvrzení o objednávce Vám přijde také email s informacemi k
                    platbě s QR kódem.</p>
                <p><small>V případě s problémy s oznamováním o objednávce kontaktujte prosím podporu na adrese <a
                            class="has-text-black"
                            href="mailto:podpora.veshop@vespotok.net">podpora.veshop@vespotok.net</a></small></p>
                <br>
                <div class="buttons">
                    <b-button tag="router-link" to="/" type="is-primary">Zpět na hlavní stranu</b-button>
                </div>
            </div>
        </div>
        </div></div>
    </div>
</template>

<script>
    export default {
        name: 'veShopTopbar',
        title: "Objednávka - Bedýnky ze dřeva",
        data() {
            return {}
        },
        methods: {

        },
        mounted() {
            this.$Progress.finish();this.$parent.getCart();
        }
    }
</script>